import { defineStore } from "pinia";
import { getAccount } from "@wagmi/core";
import { getLoginToken, getSignMessage } from "@/api/api";
import { useBlockChainStore } from "@/store/blockchain";

export const useAuthStore = defineStore("auth", {
  state: () => ({ user: null }),
  getters: {
    
  },
  actions: {
    async connect() {
      try {
        const { getPersonalSign, account } = useBlockChainStore();

        // Retrieve signature and login token
        const sig = await getSignMessage(account.address); // Step 1: Get the sign message
        const signData = await getPersonalSign(account.address, sig.data); // Step 2: Sign the message
        const userData = await getLoginToken(account.address, signData.data); // Step 3: Get the login token

        // Store necessary data in localStorage
        localStorage.setItem("WEB3_CONNECT_CACHED_PROVIDER", '"injected"'); // For backward compatibility 
        localStorage.setItem("token", userData.data.loginToken);

        // Update the user data in the component
        this.user = userData.data;

        // Redirect to user center page after successful login
        window.location.href = "/v1/#/user/userCenter?index=1";
      } catch (e) {
        console.error(e);
      }
    },
    disconnect() {
      console.log("I am disconnecting from auth");
      localStorage.clear();
      this.account = null;
    },
  },
});
